import { Badge, Avatar, Stack, IconButton, Tooltip } from '@mui/material';
import { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import BugIcon from '../../../../assets/icons/bug_c.svg';
import FrustrationIcon from '../../../../assets/icons/frustration_c.svg';
import IdeaIcon from '../../../../assets/icons/idea_c.svg';
import SatisfactionIcon from '../../../../assets/icons/satisfaction.svg';
import { useFacet } from '@koopajs/react';

const GrayBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: 'gray'
  }
}));

const TYPES: { key: string; icon: string }[] = [
  { key: 'bug', icon: BugIcon },
  { key: 'idea', icon: IdeaIcon },
  { key: 'frustration', icon: FrustrationIcon },
  { key: 'satisfaction', icon: SatisfactionIcon }
];

export const TypesFacet: React.FC = () => {
  const facet = useFacet({
    facetKey: 'type.keyword',
    path: '/observations',
    isSingleValue: true
  });

  return (
    <Stack direction="row" justifyContent="space-around" spacing={1}>
      {TYPES.map((t) => (
        <Tooltip key={t.key} title={t.key}>
          <IconButton color="secondary" sx={{ padding: 0 }} onClick={facet.addFacetFilter(t.key) as any}>
            <GrayBadge
              overlap="circular"
              color="secondary"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={facet.getCountForKey(t.key)}
            >
              <Avatar
                alt={t.key}
                src={t.icon}
                sx={{
                  width: 40,
                  height: 40,
                  filter: facet.selectedValue && t.key !== facet.selectedValue ? 'grayscale(100%)' : ''
                }}
              />
            </GrayBadge>
          </IconButton>
        </Tooltip>
      ))}
    </Stack>
  );
};
