import React, { useCallback } from 'react';
import { Dialog, Form, ErrorMessage } from '@koopajs/mui';
import { useFileUpload, useResourceCreate } from '@koopajs/react';
import { Typography, Box, Stack, Tooltip, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useWorkspaceShow } from '@koopajs/react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export const CreateObservation: React.FC = () => {
  const fileUpload = useFileUpload();
  const { errorMessage, createResource } = useResourceCreate({ path: '/observations' });
  const { workspace } = useWorkspaceShow();

  const handleCopyLink = useCallback(async (): Promise<void> => {
    await navigator.clipboard.writeText(`Hoopback ${workspace?.name} <${workspace?.inboundEmailAddress}>`);
  }, [workspace?.name, workspace?.inboundEmailAddress]);

  const handleSubmit = useCallback(
    async (formData: any): Promise<boolean> => {
      try {
        if (formData.uri[0]) {
          const fileUri = await fileUpload.upload(formData.uri[0]);
          return await createResource({
            ...formData,

            attachedFileFormat: formData.uri[0].type,
            attachedFileName: formData.uri[0].name,
            attachedFileSizeBytes: formData.uri[0].size,
            attachedFileUri: fileUri
          });
        } else {
          return await createResource(formData);
        }
      } catch (e) {
        return false;
      }
    },
    [fileUpload]
  );
  return (
    <Dialog.Form dialogKey="createObservation" title="Add a feedback" onSubmit={handleSubmit}>
      <>
        <ErrorMessage error={errorMessage} />
        <Form.Select
          name="type"
          label="Type"
          options={[
            { id: 'bug', label: '🐞 Bug' },
            { id: 'idea', label: '💡 Idea' },
            { id: 'satisfaction', label: '🥰 Satisfaction' },
            { id: 'frustration', label: '😡 Frustration' }
          ]}
        />
        <Form.TextMultiLine name="content" label="Content" rows={5} />
        <Form.Autocomplete
          optional
          name="person.email"
          label="Contact's Email"
          autocompleteType="person-email"
        />
        <Form.TextField optional name="person.name" label="Contact's Name" />
        <Form.TextField optional name="uri" label="" type="file" />

        <Form.Hidden name="isOpen" />
        {workspace?.inboundEmailAddress && (
          <>
            <Divider sx={{ m: 2 }}>
              <Typography variant="caption">OR</Typography>
            </Divider>
            <Box>
              <Box>
                <Typography>Add feedback via email</Typography>
                <Typography variant="caption">
                  Use your unique address to forward feedback emails to {workspace?.name}.
                </Typography>
              </Box>
              <Stack direction="column" alignItems="left" justifyContent="left">
                <Box sx={{ border: '1px dotted grey', borderRadius: '4px', px: 3, my: 2 }}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography sx={{ display: { xs: 'none', lg: 'block' } }}>
                        {workspace?.inboundEmailAddress as string}
                      </Typography>
                      <Typography sx={{ display: { lg: 'none' } }}>Hoopback ${workspace?.name}</Typography>
                    </Box>
                    <Box>
                      <Tooltip title="Copy to clipboard">
                        <IconButton onClick={handleCopyLink}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Open in your mail app">
                        <IconButton
                          href={`mailto:Hoopback ${workspace?.name} <${workspace?.inboundEmailAddress}>`}
                        >
                          <ForwardToInboxIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </>
        )}
      </>
    </Dialog.Form>
  );
};
