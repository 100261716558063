import React from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FacetList } from './FacetList';
import { AccessValidator } from '@koopajs/mui';

interface ISidebarProps {
  onShowCreateObservationDialog: () => void;
  onHideSidebar: () => void;
  isProcessing: boolean;
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'none', lg: 'flex' }, justifyContent: 'left' }}>
        <AccessValidator permissions="member:write">
          {(access) => (
            <>
              {access.hasPermissions ? (
                <Button
                  //fullWidth
                  variant="contained"
                  disableElevation
                  sx={{ mx: 2, mt: 4.5, mb: 3, px: 4, py: 1.5, borderRadius: 5 }}
                  startIcon={<AddIcon />}
                  disabled={props.isProcessing}
                  onClick={props.onShowCreateObservationDialog}
                >
                  Add Feedback
                </Button>
              ) : (
                <access.TooltipPermission>
                  <Button
                    //fullWidth
                    variant="contained"
                    disableElevation
                    sx={{ mx: 2, mt: 4.5, mb: 3, px: 4, py: 1.5, borderRadius: 5 }}
                    startIcon={<AddIcon />}
                    disabled
                  >
                    Add Feedback
                  </Button>
                </access.TooltipPermission>
              )}
            </>
          )}
        </AccessValidator>
      </Box>

      <Box sx={{ marginTop: { xs: 4, lg: 0 } }}>
        <FacetList />
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={{ display: { xs: 'block', md: 'block', lg: 'none' }, mx: 10, my: 3 }}
        onClick={props.onHideSidebar}
      >
        View results
      </Button>
    </>
  );
};
