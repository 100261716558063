import React, { useCallback, useState, useEffect } from 'react';
import { useWorkspaceShow, useWorkspaceUpdate, useFileUpload } from '@koopajs/react';
import { Settings, ErrorMessage } from '@koopajs/mui';
import { Api } from '@koopajs/app';
import { Divider, Box } from '@mui/material';
import { Helmet } from 'react-helmet';

export const SettingsGeneral: React.FC = () => {
  const { workspace, errorMessage } = useWorkspaceShow();
  const { updateWorkspace } = useWorkspaceUpdate();
  const { upload } = useFileUpload();
  const [stripeLink, setStripeLink] = useState<string>('');

  useEffect(() => {
    const getToken = async (): Promise<void> => {
      const { data } = await Api.client.get('/stripe/session');
      setStripeLink(data.url);
    };

    getToken().catch((e) => true);
  }, [workspace]);

  const handleProfileChange = useCallback(
    async (values: { [k: string]: unknown }): Promise<boolean> => {
      return await updateWorkspace(values);
    },
    [updateWorkspace]
  );

  const handlePhotoChange = useCallback(
    async (photo: Blob): Promise<void> => {
      const icon = await upload(photo, 'cdn');
      await handleProfileChange({ icon });
    },
    [handleProfileChange]
  );

  if (!workspace) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Settings - Hoopback</title>
      </Helmet>
      <Box>
        <>
          <ErrorMessage error={errorMessage} />
          <Box sx={{ py: 2 }}>
            <Settings.PhotoEditor
              label="Workspace Icon"
              description="An icon helps personalize your workspace."
              photoUrl={workspace.icon || ''}
              handleValueChange={handlePhotoChange}
            />
          </Box>

          <Divider />

          <Box sx={{ py: 2 }}>
            <Settings.Link
              label="Plan"
              value={workspace.premiumStatus ? `Premium ${workspace.premiumStatus}` : 'Free plan'}
              url={stripeLink}
            />
          </Box>

          <Divider />

          <Box sx={{ py: 2 }}>
            <Settings.FieldEditor
              label="Name"
              value={workspace.name || 'Unknown'}
              description="The name help personalize your product experience."
              fields={[{ key: 'name', label: 'Name' }]}
              defaultValues={workspace}
              handleValueChange={handleProfileChange}
            />
          </Box>

          {workspace.inboundEmailAddress && (
            <>
              <Divider />

              <Box sx={{ py: 2 }}>
                <Settings.Link
                  label="Email Inbound"
                  value={`Hoopback ${workspace.name} <${workspace.inboundEmailAddress}>`}
                  url={`mailto:Hoopback ${workspace.name} <${workspace.inboundEmailAddress}>`}
                />
              </Box>
            </>
          )}

          <Divider />

          <Box sx={{ py: 2 }}>
            <Settings.ReadOnly label="Workspace ID" value={`w:${workspace.id}`} />
          </Box>
        </>
      </Box>
    </>
  );
};
