import React, { useCallback } from 'react';
import { useComponentVisibility, useResourceShow, useResourceUpdate, useWorkspaceShow } from '@koopajs/react';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import { AccessValidator } from '@koopajs/mui';

import {
  Card,
  Box,
  Link,
  Alert,
  CardHeader,
  CardActions,
  Chip,
  IconButton,
  Avatar,
  Button,
  Grid
} from '@mui/material';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CloseIcon from '@mui/icons-material/Close';

interface IIntegration {
  logoUrl: string;
  name: string;
  key: string;
  description: string;
  isAvailable: boolean;
  isActive?: boolean;
}

const integrations: IIntegration[] = [
  {
    logoUrl: '//logo.clearbit.com/hubspot.com',
    name: 'Hubspot',
    key: 'integration-adobe-sign',
    description: 'Import your data from HubSpot to Hoopback',
    isAvailable: false
  },
  {
    logoUrl: '//logo.clearbit.com/zendesk.com',
    name: 'Zendesk',
    key: 'integration-zendesk',
    description: 'Import your data from Zendesk to Hoopback',
    isAvailable: false,
    isActive: false
  },
  {
    logoUrl: '//logo.clearbit.com/freshdesk.com',
    name: 'Freshdesk',
    key: 'integration-freshdesk',
    description: 'Import your data from Freshdesk to Hoopback',
    isAvailable: false
  },
  {
    logoUrl: '//logo.clearbit.com/google.com',
    name: 'Google Drive',
    key: 'integration-google-drive',
    description: 'Upload documents from your Drive to Hoopback',
    isAvailable: false
  },
  {
    logoUrl: '//logo.clearbit.com/dropbox.com',
    name: 'Dropbox',
    key: 'integration-dropbox',
    description: 'Upload documents from Dropbox to Hoopback',
    isAvailable: false
  },
  {
    logoUrl: 'https://images.iwn.team/files/36512a46-be59-4264-b5c4-451248354a01/public',
    name: 'Email',
    key: 'integration-email',
    description: 'Send documents from your email to Hoopback',
    isAvailable: false
  }
];

export const SettingsIntegrations: React.FC = () => {
  const feedbackForm = useComponentVisibility('feedback');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { workspace } = useWorkspaceShow();
  const { resource } = useResourceShow<{ id: string; featureInterests?: string[] }>({
    path: '/workspaces',
    id: workspace?.id || ''
  });
  const { updateResource, isProcessing } = useResourceUpdate({
    path: '/workspaces',
    id: workspace?.id || ''
  });

  const handleAddNotification =
    (integrationKey: string): React.EventHandler<React.MouseEvent> =>
    async (): Promise<void> => {
      if (!resource) {
        return;
      }

      const featureInterests = resource.featureInterests
        ? [...resource.featureInterests, integrationKey]
        : [integrationKey];

      await updateResource({ featureInterests });
    };

  const handleRemoveNotification =
    (integrationKey: string): React.EventHandler<React.MouseEvent> =>
    async (): Promise<void> => {
      const featureInterests = resource?.featureInterests?.filter((item) => item !== integrationKey);
      await updateResource({ featureInterests });
    };

  const handleShowFeedback = useCallback(() => {
    feedbackForm.setVisibleWithContext({
      defaultValues: { namespace: 'integrations' },
      onCreate: () => {
        enqueueSnackbar(`Feedback successfully sent, Thanks!`, {
          variant: 'success',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });
      }
    });
  }, []);

  const isNotificationActive = (key: string): boolean => {
    if (resource && resource.featureInterests && resource.featureInterests.includes(key)) {
      return true;
    }

    return false;
  };

  const renderHeaderAction = (item: IIntegration): React.ReactNode => {
    if (item.isAvailable && item.isActive) {
      return <Chip color="success" size="small" variant="outlined" label="Enabled" />;
    } else if (isNotificationActive(item.key) && !item.isAvailable) {
      return (
        <IconButton color="primary" aria-label="settings">
          <NotificationsActiveIcon color="primary" />
        </IconButton>
      );
    } else if (!isNotificationActive(item.key) && !item.isAvailable) {
      return (
        <IconButton disabled aria-label="settings">
          <NotificationsNoneIcon />
        </IconButton>
      );
    }
  };

  const renderButtonName = (item: IIntegration): React.ReactNode => {
    if (item.isAvailable && item.isActive) {
      return (
        <AccessValidator permissions="owner">
          {(access) => (
            <>
              {access.hasPermissions && (
                <Button disabled={isProcessing} size="small" color="inherit">
                  Remove Integration
                </Button>
              )}
            </>
          )}
        </AccessValidator>
      );
    } else if (isNotificationActive(item.key) && !item.isAvailable) {
      return (
        <AccessValidator permissions="owner">
          {(access) => (
            <>
              {access.hasPermissions && (
                <Button
                  disabled={isProcessing}
                  size="small"
                  color="inherit"
                  onClick={handleRemoveNotification(item.key)}
                >
                  Disable notification
                </Button>
              )}
            </>
          )}
        </AccessValidator>
      );
    } else if (!isNotificationActive(item.key) && !item.isAvailable) {
      return (
        <AccessValidator permissions="owner">
          {(access) => (
            <>
              {access.hasPermissions && (
                <Button disabled={isProcessing} size="small" onClick={handleAddNotification(item.key)}>
                  Notify Me When Available
                </Button>
              )}
            </>
          )}
        </AccessValidator>
      );
    } else if (item.isAvailable && !item.isActive) {
      return (
        <AccessValidator permissions="owner">
          {(access) => (
            <>
              {access.hasPermissions ? (
                <Button disabled={isProcessing} size="small">
                  Add Integration
                </Button>
              ) : (
                <access.TooltipPermission>
                  <Button disabled={isProcessing} size="small">
                    Add Integration
                  </Button>
                </access.TooltipPermission>
              )}
            </>
          )}
        </AccessValidator>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Settings - Hoopback</title>
      </Helmet>

      <Box sx={{ my: 2 }}>
        <Alert severity="info">
          Any other integration you would like to see here?{' '}
          <Link href="#" onClick={handleShowFeedback}>
            Let us know!
          </Link>
        </Alert>
      </Box>
      <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 12 }}>
        {integrations
          .sort((item1, item2) => (item1.name > item2.name ? 1 : -1))
          .map((item) => (
            <Grid key={item.logoUrl} item xs={1} md={2} lg={4}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar
                      alt={item.name}
                      variant="rounded"
                      src={item.logoUrl}
                      sx={{ objectFit: 'cover', height: '100%' }}
                    />
                  }
                  action={renderHeaderAction(item)}
                  titleTypographyProps={{ variant: 'h6' }}
                  title={item.name}
                  subheader={item.description}
                />
                <CardActions sx={{ pl: 8, pt: 0 }}>{renderButtonName(item)}</CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
};
