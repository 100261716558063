import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Account } from './Account';
import { NotFound } from './NotFound';
import { Settings } from './Settings';
import { Observations } from './Observations';
import { Analytics } from './Analytics';
import { Pages } from '@koopajs/mui';

const BillingPage = (): React.ReactElement => {
  return <Pages.BillingPage appName="Hoopback" />;
};

export const Router: React.FC = () => (
  <Switch>
    <Redirect exact path="/" to="/observations" />
    <Route path="/account" component={Account} />
    <Route path="/settings" component={Settings} />
    <Route path="/analytics" component={Analytics} />
    <Route path="/observations/:id?" exact component={Observations} />
    <Route path="/invitation/:id" exact component={Pages.InvitationPage} />
    {/* <Route exact path="/billing?status=success" component={Billing} />
    <Route exact path="/billing?status=error" component={Billing} /> */}
    <Route path="/billing" exact component={BillingPage} />
    {/* <Redirect path="/billing" to="/settings" /> */}
    <Route path="*" component={NotFound} />
  </Switch>
);
