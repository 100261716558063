import { Date, TagList } from '@koopajs/mui';
import { IObservation } from '../../../types';
import {
  CardActionArea,
  Box,
  Typography,
  Divider,
  Chip,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Avatar
} from '@mui/material';
import { useHistory } from 'react-router';
import React, { useCallback } from 'react';
import { ImageType } from './ImageType';
import { useTags } from '@koopajs/react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export const Item: React.FC<{
  observation: IObservation;
  onTagClick: (facetKey: string, valueKey: string) => void;
}> = ({ observation, onTagClick }) => {
  const history = useHistory();

  const { tags } = useTags({ path: '/observations', id: observation.id, resource: observation });

  const handleObservationShow = useCallback(() => {
    history.push(`/observations/${observation.id}`);
  }, [observation.id]);

  const handleClickTag = useCallback((tag: string) => {
    return (e: React.MouseEvent) => {
      e.stopPropagation();
      onTagClick('tags.keyword', tag);
    };
  }, []);

  const handleClickSentiment = useCallback((sentiment: string) => {
    return (e: React.MouseEvent) => {
      e.stopPropagation();
      onTagClick('sentiment.keyword', sentiment);
    };
  }, []);

  const handleClickLanguage = useCallback((language: string) => {
    return (e: React.MouseEvent) => {
      e.stopPropagation();
      onTagClick('language.keyword', language);
    };
  }, []);

  return (
    <Card>
      <CardActionArea onClick={handleObservationShow}>
        <CardHeader
          sx={{ display: 'flex' }}
          avatar={
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingRight: '25px' }}>
              <Avatar sx={{ backgroundColor: 'white', width: 50, height: 50 }}>
                <ImageType type={observation.type} />
              </Avatar>
              <Typography align="center" variant="caption">
                {observation.type || 'add type'}
              </Typography>
            </Box>
          }
          action={
            <Typography align="center" variant="caption">
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <Box>Created on &nbsp;</Box>
                <Box sx={{ paddingRight: '16px' }}>
                  <Date isoDate={observation.$createdAt} format="yyyy-MM-dd" />
                </Box>
              </Stack>
            </Typography>
          }
          title={
            <Box sx={{ marginLeft: 0, paddingLeft: 0 }}>
              {observation.person?.email || observation.person?.name || 'Anonymous'}
            </Box>
          }
          subheader={
            <Stack direction={{ xs: 'column', md: 'row' }}>
              <Box>
                <>
                  {observation.sourceEmail && `Email forwarded by ${observation.sourceEmail}`}
                  {observation.sourceRoute && observation.sourceRoute}
                  {!observation.sourceRoute && !observation.sourceEmail && 'Manual entry'}
                  &nbsp; &bull; &nbsp;
                </>
              </Box>
              <Box>
                Last updated <Date isoDate={observation.$updatedAt} />
              </Box>
            </Stack>
          }
        />
        <Divider sx={{ borderColor: '#00000008' }} />
        <CardContent>
          <Typography>{observation.content}</Typography>

          <Stack
            sx={{ marginTop: 3 }}
            direction={{ xs: 'column', md: 'row' }}
            spacing={1}
            alignItems={{ xs: 'left', lg: 'center' }}
          >
            <Stack direction="row" spacing={1}>
              {observation?.sentiment && (
                <Box>
                  <Chip
                    icon={<AutoAwesomeIcon style={{ fill: '#D99AFF' }} />}
                    sx={{ textTransform: 'capitalize' }}
                    variant="outlined"
                    label={observation?.sentiment?.toLowerCase()}
                    onClick={handleClickSentiment(observation?.sentiment)}
                  />
                </Box>
              )}

              {observation?.language && (
                <Box>
                  <Chip
                    variant="outlined"
                    icon={<AutoAwesomeIcon style={{ fill: '#D99AFF' }} />}
                    label={observation?.language}
                    onClick={handleClickLanguage(observation?.language)}
                  />
                </Box>
              )}
            </Stack>

            <TagList tags={tags}>
              {(tag: string) => <Chip label={tag} onClick={handleClickTag(tag)} key={tag} />}
            </TagList>
            <Box sx={{ flexGrow: 1 }} />
            {observation.commentCount && (
              <Box
                sx={{
                  pl: 1,
                  pr: 1,
                  bgcolor: '#fffbd6',
                  width: 'fit-content',
                  height: 'fit-content'
                }}
              >
                + {observation.commentCount}
                {observation?.commentCount > 1 ? ' notes ' : ' note '}
              </Box>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
