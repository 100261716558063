import {
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
  Avatar,
  Box,
  Divider,
  Skeleton as MuiSkeleton,
  Typography
} from '@mui/material';

export const Skeleton: React.FC = () => {
  return (
    <>
      {[1, 2, 3].map((card) => (
        <Card key={card} sx={{ margin: 3 }}>
          <CardActionArea>
            <CardHeader
              sx={{ display: 'flex' }}
              avatar={
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingRight: '25px' }}>
                  <Avatar sx={{ backgroundColor: 'white', width: 50, height: 50 }}>
                    <MuiSkeleton />
                  </Avatar>
                  <Typography align="center" variant="caption" />
                </Box>
              }
              title={
                <Box sx={{ marginLeft: 0, paddingLeft: 0 }}>
                  <MuiSkeleton />
                </Box>
              }
            />
            <Divider sx={{ borderColor: '#00000008' }} />
            <CardContent>
              <MuiSkeleton />
              <Box sx={{ marginTop: 3 }}>
                <MuiSkeleton />
              </Box>
              <MuiSkeleton />
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </>
  );
};
