import { Box, Typography, Button, Container } from '@mui/material';
import feedback from '../../../assets/feedback2.png';
import AddIcon from '@mui/icons-material/Add';
import { AccessValidator } from '@koopajs/mui';

interface IEmptyStateProps {
  isProcessing: boolean;
  onShowCreateObservationDialog: () => void;
}

export const EmptyState: React.FC<IEmptyStateProps> = ({ isProcessing, onShowCreateObservationDialog }) => {
  return (
    <Container sx={{ textAlign: 'center', marginTop: 10 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: -2,
          marginBottom: 5
        }}
      >
        <img src={feedback} srcSet="" loading="lazy" style={{ width: 300 }} />
      </Box>

      <Box>
        <Typography variant="h4" component="h4">
          Welcome to Hoopback
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4, mt: 1 }}>
          Gather relevant feedback insights from your apps here and share them with your team.
        </Typography>
        <AccessValidator permissions="member:write">
          {(access) => (
            <>
              {access.hasPermissions ? (
                <Button
                  //fullWidth
                  variant="contained"
                  disableElevation
                  sx={{ mx: 2, px: 4, py: 1.5, borderRadius: 5 }}
                  startIcon={<AddIcon />}
                  disabled={isProcessing}
                  onClick={onShowCreateObservationDialog}
                >
                  Add Feedback
                </Button>
              ) : (
                <access.TooltipPermission>
                  <Button
                    //fullWidth
                    variant="contained"
                    disableElevation
                    sx={{ mx: 2, px: 4, py: 1.5, borderRadius: 5 }}
                    startIcon={<AddIcon />}
                    disabled
                  >
                    Add Feedback
                  </Button>
                </access.TooltipPermission>
              )}
            </>
          )}
        </AccessValidator>
      </Box>
    </Container>
  );
};
