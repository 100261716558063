import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserShow, useWorkspaceShow, useComponentVisibility } from '@koopajs/react';
import { TopBarUser } from '@koopajs/mui/dist/components/App/TopBarUser';
import { Logo } from '../Logo';
import { Button, Toolbar, Box } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import AssistantIcon from '@mui/icons-material/Assistant';

export const Header: React.FC = () => {
  const match = useRouteMatch<{ id: string }>('/:id');
  const [activePage, setActivePage] = useState<string | undefined>('');

  useEffect(() => {
    setActivePage(match?.params?.id);
  }, [match]);

  const { user } = useUserShow();
  const { workspace } = useWorkspaceShow();
  const userMenu = useComponentVisibility('userMenu');
  const isLargeScreen = useMediaQuery('(min-width:700px)');

  const usernames: [string] | [string, string] = [`${user?.firstname} ${user?.lastname}`];
  if (workspace?.name) {
    usernames.push(workspace.name);
  }

  return (
    <Toolbar sx={{ minHeight: '64px', height: '64px' }}>
      <Link to="/" style={{ textDecoration: 'none', marginTop: 12 }}>
        <Logo />
      </Link>

      <div style={{ textAlign: 'right', flex: 'auto' }} />

      <Box sx={{ textAlign: 'right', display: 'flex' }}>
        <Button
          sx={{
            mr: isLargeScreen ? 5 : ''
          }}
          component={Link}
          to="/observations"
          endIcon={activePage === 'observations' ? <AssistantIcon /> : <AssistantOutlinedIcon />}
        >
          {isLargeScreen && 'Feedback'}
        </Button>
        <Button
          sx={{
            mr: isLargeScreen ? 5 : ''
          }}
          component={Link}
          to="/analytics"
          endIcon={activePage === 'analytics' ? <AssessmentIcon /> : <AssessmentOutlinedIcon />}
        >
          {isLargeScreen && 'Analytics'}
        </Button>
      </Box>
      <TopBarUser
        usernames={isLargeScreen ? usernames : undefined}
        avatar={user?.profilePicture}
        workspaceAvatar={workspace?.icon}
        setMenuUserVisible={userMenu.setVisible}
      />
    </Toolbar>
  );
};
