import React, { useState, useCallback } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import heart from 'assets/heart.png';
import heartHover from 'assets/heartHover2.png';

export const Logo: React.FC = () => {
  const [isHover, setIsHover] = useState(false);

  const handleOnMouseOver = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleOnMouseOut = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <Stack direction="row" justifyContent="left" alignItems="center" sx={{ mb: 3 }}>
      <Box
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        sx={{
          padding: '2px 5px',
          fontSize: 24,
          color: '#c52795',
          letterSpacing: -1,
          transform: 'scaleY(90%)',
          display: 'flex',
          alignItems: 'baseline'
        }}
      >
        <img
          src={isHover ? heartHover : heart}
          alt="Hoopback: Feedback is a gift"
          width={35}
          style={{ position: 'relative', top: 5, left: -5 }}
        />
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          Hoopback
        </Typography>
      </Box>
    </Stack>
  );
};
