import { Box, Stack, Button, Fab } from '@mui/material';

interface IMobileSidebarProps {
  onShowFilters: () => void;
  onShowCreateObservationDialog: () => void;
  isProcessing: boolean;
}

export const MobileSidebar: React.FC<IMobileSidebarProps> = ({
  onShowFilters,
  isProcessing,
  onShowCreateObservationDialog
}) => {
  return (
    <Box
      component="div"
      sx={{
        position: 'fixed',
        zIndex: 1200,
        left: 0,
        right: 0,
        bottom: 0,
        height: 70,
        display: { xs: 'block', md: 'block', lg: 'none' }
      }}
    >
      <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'center' }}>
        <Fab
          variant="extended"
          color="inherit"
          component={Button}
          size="medium"
          sx={{
            borderRadius: '4px'
          }}
          onClick={onShowFilters}
        >
          Filters
        </Fab>
        <Fab
          variant="extended"
          color="inherit"
          component={Button}
          size="medium"
          sx={{
            borderRadius: '4px'
          }}
          disabled={isProcessing}
          onClick={onShowCreateObservationDialog}
        >
          Add Feedback
        </Fab>
      </Stack>
    </Box>
  );
};
