import { Stack, Box, Divider } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Tooltip from '@mui/material/Tooltip';
import { TypesFacet } from './Facets/TypesFacet';
import { AccordionWithSlider } from './AccordionWithSlider';
import { Facets } from '@koopajs/mui';

export const FacetList: React.FC = () => {
  const path = '/observations';

  return (
    <Box>
      <TypesFacet />

      <Divider sx={{ marginTop: 5 }} />

      <Facets.AccordionFacet path={path} facetKey="tags.keyword" title="Tags" />
      <Facets.AccordionFacet
        path={path}
        facetKey="language.keyword"
        title={
          <Tooltip title="AI Powered">
            <Stack direction="row" alignItems="center">
              <AutoAwesomeIcon fontSize="small" sx={{ mr: 1 }} style={{ fill: '#D99AFF' }} /> Language
            </Stack>
          </Tooltip>
        }
      />

      <Facets.AccordionFacet
        path={path}
        facetKey="sentiment.keyword"
        title={
          <Tooltip title="AI Powered">
            <Stack direction="row" alignItems="center">
              <AutoAwesomeIcon fontSize="small" sx={{ mr: 1 }} style={{ fill: '#D99AFF' }} /> Sentiment
            </Stack>
          </Tooltip>
        }
      />
      <Facets.AccordionFacet path={path} facetKey="email.keyword" title="Email" />
      <Facets.AccordionFacet path={path} facetKey="commentCount" title="Notes" />
      {/* <AccordionWithSlider facetKey="commentCount" path={path} title="Comments" /> */}
    </Box>
  );
};
