import AddIcon from '../../../assets/icons/add.svg';
import BugIcon from '../../../assets/icons/bug_c.svg';
import FrustrationIcon from '../../../assets/icons/frustration_c.svg';
import IdeaIcon from '../../../assets/icons/idea_c.svg';
import SatisfactionIcon from '../../../assets/icons/satisfaction.svg';

export const ImageType: React.FC<{ type?: string }> = (props) => {
  if (props.type === 'bug') {
    return <img src={BugIcon} />;
  } else if (props.type === 'idea') {
    return <img src={IdeaIcon} />;
  } else if (props.type === 'satisfaction') {
    return <img src={SatisfactionIcon} />;
  } else if (props.type === 'frustration') {
    return <img src={FrustrationIcon} />;
  } else {
    return <img src={AddIcon} />;
  }
};
