import React, { useCallback } from 'react';
import { useResourceList, useSearchParams, useComponentVisibility } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { ErrorMessage, Layout } from '@koopajs/mui';
import { Item } from '../components/Lists/Observations/Item';
import { ObservationShow } from './ObservationShow';
import { IObservation } from 'types';
import { Box, Avatar, Typography } from '@mui/material';
import { Skeleton } from '../components/Lists/Observations/Skeleton';
import { MobileSidebar } from '../components/Lists/Observations/MobileSidebar';
import { Sidebar } from '../components/Lists/Observations/Sidebar';
import { EmptyState } from 'components/Lists/Observations/EmptyState';
import { NoSearchResultState } from 'components/Lists/Observations/NoSearchResultState';
import { ImageType } from 'components/Lists/Observations/ImageType';
import { ListHeader } from '@koopajs/mui';

export const Observations: React.FC = () => {
  const sidebarVisibility = useComponentVisibility('sidebar');
  const createObservationDialog = useComponentVisibility('createObservation');
  const search = useSearchParams();
  const request = useResourceList<IObservation>({ path: '/observations', searchParams: search.searchParams });

  //***********FILTERS***********//
  const handleAddFilter = useCallback(
    (facetKey: string, valueKey: string): void => {
      const filter = `${facetKey}:"${valueKey}"`;

      if (!search.searchParams.filters?.includes(filter)) {
        search.addFilter(filter);

        TrackEvent('filters', {
          filter: facetKey,
          filtersCount: search.searchParams.filters ? search.searchParams.filters.length + 1 : 1
        });
      }
    },

    [search]
  );

  //***********CHIPS***********//
  const renderChips = useCallback((key: string, value: string) => {
    if (key === 'type.keyword') {
      return (
        <Avatar sx={{ width: '20px', height: '20px', backgroundColor: '#ffffff00' }}>
          <ImageType type={value} />
        </Avatar>
      );
    }
    if (key === 'commentCount') {
      const rangeValue = value.replace('[', '').replace(']', '').split(' TO ');
      return (
        <Typography variant="caption" display="block">
          {rangeValue.length > 1
            ? `${rangeValue[0]} - ${rangeValue[1]} comments`
            : `${rangeValue[0]} comments`}
        </Typography>
      );
    }

    return value;
  }, []);

  //***********SORT***********//
  const handleResetSearchParams = useCallback((): void => {
    search.setSearchParams({
      query: undefined,
      filters: undefined,
      page: undefined,
      size: undefined,
      sort: undefined
    });
  }, []);

  //***********----***********//

  const isEmptyBecauseOfDatabase = (): boolean => {
    return request.state === 'loaded-empty' && Object.keys(search.searchParams).length === 0; // AND WE DO NOT HAVE AN ACTIVE SEARCH
  };

  //***********************************************************************************************************************/

  if (isEmptyBecauseOfDatabase()) {
    return (
      <EmptyState
        onShowCreateObservationDialog={createObservationDialog.setVisible}
        isProcessing={request.isProcessing}
      />
    );
  }

  return (
    <Layout.PageContainer
      fluidContainer
      sidebar={
        <Sidebar
          isProcessing={request.isProcessing}
          onShowCreateObservationDialog={createObservationDialog.setVisible}
          onHideSidebar={sidebarVisibility.setHidden}
        />
      }
    >
      <ListHeader
        title="Feedback Inbox"
        requestState={request.state}
        totalCount={request.totalCount}
        sortMenuOptions={[
          {
            label: 'Newest to oldest',
            sort: '$createdAt:desc'
          },
          {
            label: 'Oldest to newest',
            sort: '$createdAt:asc'
          },

          { label: 'Most commented', sort: 'commentCount:desc' }
        ]}
        tabs={[
          {
            id: 'default',
            label: 'Open'
          },
          {
            id: 'archived',
            label: 'archived'
          },
          {
            id: 'all',
            label: 'all'
          }
        ]}
        renderChipContent={renderChips}
      />

      <ErrorMessage error={request?.errorMessage} />

      {request.state === 'loading' && <Skeleton />}
      <MobileSidebar
        onShowFilters={sidebarVisibility.setVisible}
        isProcessing={request.isProcessing}
        onShowCreateObservationDialog={createObservationDialog.setVisible}
      />
      {(request.state === 'loaded' || request.state === 'reloading') &&
        request.resources.map((observation) => {
          return (
            <Box sx={{ mt: 1, mb: 1 }} key={observation.id}>
              <Item observation={observation} onTagClick={handleAddFilter} />
            </Box>
          );
        })}

      <Box
        component="div"
        sx={{
          height: 100,
          display: { xs: 'block', lg: 'none' }
        }}
      />

      {request.state === 'loaded-no-search-result' && (
        <NoSearchResultState onResetFilters={handleResetSearchParams} />
      )}
      <ObservationShow />
    </Layout.PageContainer>
  );
};
