import React from 'react';
import { Layout, User } from '@koopajs/mui';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';

import {
  Box,
  Typography,
  Divider,
  Stack,
  Card,
  Button,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@mui/material';

import { useAnalyticsClient, useAnalyticsQuery, useAnalyticsTimeDimensions } from '@koopajs/react';
import { useState, useCallback } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FrustrationIcon from '../assets/icons/frustration_c.svg';
import BugIcon from '../assets/icons/bug_cc.svg';
import IdeaIcon from '../assets/icons/idea_c.svg';
import SatisfactionIcon from '../assets/icons/satisfaction.svg';
import { PieChart, Pie } from 'recharts';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

type ValidTimeDimension = 'Today' | 'This Week' | 'This Month' | 'This Year';
const TIMEFRAMES: ValidTimeDimension[] = ['Today', 'This Week', 'This Month', 'This Year'];

const icons: { [key: string]: string } = {
  bug: BugIcon,
  idea: IdeaIcon,
  satisfaction: SatisfactionIcon,
  frustration: FrustrationIcon
};

interface IResultPivot {
  [key: string]: string | number | boolean;
}

interface IResultsPivot {
  'Observation.type'?: string;
  'Observation.count'?: number;
}

export const Analytics: React.FC = () => {
  const client = useAnalyticsClient();
  const timeDimension = useAnalyticsTimeDimensions({ field: 'Observation.createdAt' });

  const resultObservationType = useAnalyticsQuery(
    {
      measures: ['Observation.count'],
      timeDimensions: [timeDimension.currentTimeDimension],
      order: { 'Observation.count': 'desc' },
      dimensions: ['Observation.type']
    },
    client
  );

  const resultObservationTypeLastPeriod = useAnalyticsQuery(
    {
      measures: ['Observation.count'],
      timeDimensions: [timeDimension.previousTimeDimension],
      order: { 'Observation.count': 'desc' },
      dimensions: ['Observation.type']
    },
    client
  );

  const resultObservationUser = useAnalyticsQuery(
    {
      measures: ['Observation.count'],
      timeDimensions: [timeDimension.currentTimeDimension],
      order: { 'Observation.count': 'desc' },
      dimensions: ['Observation.createdBy']
    },
    client
  );

  const resultObservationSentiment = useAnalyticsQuery(
    {
      measures: ['Observation.count'],
      timeDimensions: [timeDimension.currentTimeDimension],
      order: { 'Observation.count': 'desc' },
      dimensions: ['Observation.sentiment']
    },
    client
  );

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeTimeframe = useCallback((timeframe: ValidTimeDimension) => {
    return () => {
      timeDimension.setTimeDimensions(timeframe);
    };
  }, []);

  const calculatePercentages = useCallback(
    (type: string, observations?: IResultsPivot[], lastPeriodObservations?: IResultsPivot[]) => {
      const countCurrentPeriod =
        observations?.find((observation) => observation['Observation.type'] === type)?.[
          'Observation.count'
        ] || 0;

      const countLastPeriod =
        lastPeriodObservations?.find((observation) => observation['Observation.type'] === type)?.[
          'Observation.count'
        ] || 0;

      if (countCurrentPeriod === 0 && countLastPeriod === 0) {
        return { count: `0%`, backgroundColor: '#f0f0f0', color: '#6E6E6E' };
      }

      if (countCurrentPeriod === 0) {
        return {
          count: `- ${countLastPeriod * 100}%`,
          backgroundColor: '#f4e6be',
          color: '#a36238'
        }; //brown
      }

      if (countLastPeriod === 0) {
        return {
          count: `+ ${countCurrentPeriod * 100}%`,
          backgroundColor: '#d3f2cd',
          color: '#4b7e62'
        }; //green
      }

      const result = Math.round((countCurrentPeriod / countLastPeriod) * 100);

      if (result < 100) {
        return { count: `- ${result}%`, backgroundColor: '#f4e6be', color: '#a36238' }; //brown
      } else if (result > 100) {
        return { count: `+ ${result}%`, backgroundColor: '#d3f2cd', color: '#4b7e62' }; //green
      } else {
        return { count: `${result}%`, backgroundColor: '#f0f0f0', color: '#6E6E6E' }; // grey
      }
    },
    []
  );

  const sourceType = resultObservationType?.resultSet?.tablePivot();
  const sourceUser = resultObservationUser?.resultSet?.tablePivot();
  const sourceSentiment = resultObservationSentiment?.resultSet?.tablePivot();
  const sourceTypeLastPeriod = resultObservationTypeLastPeriod?.resultSet?.tablePivot();
  const totalObservation = sourceType
    ?.map((i: IResultPivot) => Number(i['Observation.count']))
    ?.reduce((i: number, j: number) => i + j, 0);

  return (
    <Layout.PageContainer fluidContainer>
      <Typography variant={isLargeScreen ? 'h4' : 'h5'} width="100%">
        Analytics
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="text"
          color="primary"
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickMenu}
          endIcon={<KeyboardArrowDownIcon />}
        >
          <>Period: {timeDimension?.currentTimeDimension?.dateRange}</>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {TIMEFRAMES.map((time) => {
            return (
              <MenuItem onClick={handleChangeTimeframe(time)} key={time}>
                {time === timeDimension.currentTimeDimension.dateRange && (
                  <ListItemIcon>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                )}
                <ListItemText inset={time !== timeDimension.currentTimeDimension.dateRange}>
                  {time}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>

      <Typography variant="h6" gutterBottom>
        Feedback received
      </Typography>
      <Stack direction="row" spacing={1} alignItems="baseline">
        <Typography variant="h4" gutterBottom>
          {totalObservation || 0}
        </Typography>
        <Typography gutterBottom>total</Typography>
      </Stack>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: { lg: 3, md: 3, sm: 3 } }}
        >
          {['bug', 'idea', 'satisfaction', 'frustration'].map((type, index) => {
            return (
              <>
                <Box sx={{ width: '220px', py: 2 }}>
                  <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" spacing={1}>
                    <img src={icons[type]} width={isLargeScreen ? '100px' : '80px'} />
                    <Box sx={{ mb: 5, textAlign: { xs: 'center' } }}>
                      <Typography variant="caption" sx={{ fontSize: '13px' }}>
                        {`${type.charAt(0).toUpperCase() + type.slice(1)}s`}
                      </Typography>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        alignItems="center"
                        spacing={1}
                        sx={{ mt: 1 }}
                      >
                        <Typography variant="h4">
                          {resultObservationType.isLoading ? (
                            <Skeleton />
                          ) : (
                            sourceType?.find((i: IResultPivot) => i['Observation.type'] === type)?.[
                              'Observation.count'
                            ] || '0'
                          )}
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: calculatePercentages(type, sourceType, sourceTypeLastPeriod)
                              ?.backgroundColor,
                            color: calculatePercentages(type, sourceType, sourceTypeLastPeriod)?.color,
                            fontSize: '12px',
                            borderRadius: 2,
                            px: 1
                          }}
                        >
                          {resultObservationType.isLoading ? (
                            <Skeleton />
                          ) : (
                            calculatePercentages(type, sourceType, sourceTypeLastPeriod)?.count
                          )}
                        </Box>
                      </Stack>
                      <Typography variant="caption">
                        Last period{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {resultObservationType.isLoading ? (
                            <Skeleton />
                          ) : (
                            sourceTypeLastPeriod?.find((i: IResultPivot) => i['Observation.type'] === type)?.[
                              'Observation.count'
                            ] || '0'
                          )}
                        </span>
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                {index !== 3 && (
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ py: 2, borderColor: '#00000009' }}
                  />
                )}
              </>
            );
          })}
        </Stack>
      </Card>
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        alignItems="center"
        spacing={2}
        sx={{ mt: 4, width: '100%' }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" gutterBottom>
            Team Engagement
          </Typography>
          {resultObservationSentiment.isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={400} />
          ) : (
            <Card sx={{ pt: 2, pr: 4 }}>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={sourceUser}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="Observation.createdBy" />
                  <YAxis />
                  <Tooltip
                    // eslint-disable-next-line react/jsx-no-bind
                    content={(params) => {
                      return <User id={params.label} />;
                    }}
                  />
                  <Label />
                  <Bar dataKey="Observation.count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Card>
          )}
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" gutterBottom>
            User&apos;s Overall Sentiment
          </Typography>

          {resultObservationSentiment.isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={400} />
          ) : (
            <Card sx={{ pb: 2 }}>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={sourceSentiment}
                    cx="50%"
                    cy={200}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#86888a"
                    paddingAngle={5}
                    dataKey="Observation.count"
                    nameKey="Observation.sentiment"
                  >
                    {sourceSentiment &&
                      sourceSentiment.map((entry: IResultPivot, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            {
                              NEGATIVE: '#EB615C',
                              POSITIVE: '#00C49F',
                              MIXED: '#F2BC23',
                              NEUTRAL: 'orange'
                            }[entry['Observation.sentiment'] as string] || 'grey'
                          }
                        />
                      ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Card>
          )}
        </Box>
      </Stack>
      <Box sx={{ padding: 5 }} />
    </Layout.PageContainer>
  );
};
