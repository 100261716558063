import { Typography, Link, Container } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useSearchParams } from '@koopajs/react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

export const NoSearchResultState: React.FC<{ onResetFilters: () => void }> = ({ onResetFilters }) => {
  const { searchParams } = useSearchParams();

  if (
    (searchParams.view === 'archived' && Object.keys(searchParams).length === 1) ||
    (searchParams.view === 'archived' && searchParams.sort)
  ) {
    return (
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ChatBubbleOutlineIcon fontSize="large" sx={{ mb: 2 }} />
        <Typography variant="h5" component="div" gutterBottom>
          There aren&apos;t any archived feedback yet.
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <SearchOffIcon fontSize="large" />
      <Typography variant="h5" component="div" gutterBottom>
        There aren&apos;t any search results.
      </Typography>
      <Typography>
        Can&apos;t find what you are looking for? Try to{' '}
        <Link onClick={onResetFilters} component="button" variant="body1">
          reset the filters
        </Link>
      </Typography>
    </Container>
  );
};
