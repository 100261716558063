import React from 'react';
import { CreateObservation } from './CreateObservation';
import { Dialog } from '@koopajs/mui';
import { CreateFeedback } from './CreateFeedback';

export const Dialogs: React.FC = () => (
  <>
    <CreateObservation />
    <CreateFeedback />

    <Dialog.PlanSelector
      pricingPlans={[
        {
          title: 'Individual Vault',
          price: 'Free',
          paymentFrequency: 'For a lifetime',
          description: [
            'Sync across devices',
            'Advanced search and filters',
            'AI-powered classification',
            'Email integration'
          ]
        },
        {
          title: 'Team Vault',
          price: '$11',
          paymentFrequency: '/month, billed yearly',
          description: [
            'Everything from free plan',
            'Invite unlimited users',
            'Unlimited observations',
            '5-years history activity'
          ]
        }
      ]}
    />

    <Dialog.EditPermissions
      isDeleteVisible
      userId="fake-user"
      options={[
        { id: 'admin', label: 'Admin' },
        { id: 'member', label: 'Team member' },
        { id: 'member:read', label: 'Team member (Read only)' }
      ]}
    />

    <Dialog.InviteUser
      options={[
        { id: 'admin', label: 'Admin' },
        { id: 'member', label: 'Team member' },
        { id: 'member:read', label: 'Team member (Read only)' }
      ]}
    />
    <Dialog.CreateWorkspace title="Create a new project" description="Enter a name for your project" />
  </>
);
