import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useResourceList, useComponentVisibility } from '@koopajs/react';
import { Date, AccessValidator } from '@koopajs/mui';
import {
  Button,
  Chip,
  Paper,
  List,
  ListSubheader,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Tooltip,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const SettingsPermissions: React.FC = () => {
  const invitations = useResourceList({ path: '/invitations' });
  const users = useResourceList({ path: '/users' });
  const inviteUser = useComponentVisibility('inviteUser');
  const editUserPermissions = useComponentVisibility('editPermissions');

  const showInvitationDialog = useCallback(() => {
    inviteUser.setVisible();
  }, []);

  const handleCopyToClipboardInvitationUrl = useCallback((url: string) => {
    return async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      try {
        await navigator.clipboard.writeText(url);
      } catch (e) {
        console.log(e);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Settings - Hoopback</title>
      </Helmet>
      <div>
        <Paper>
          <List
            subheader={
              //DON'T SHOW THIS IF IT'S NOT YOUR WORKSPACE
              <ListSubheader>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>Users</Grid>
                  <Grid item>
                    <AccessValidator permissions="owner">
                      {(props) => (
                        <>
                          {props.hasPermissions && (
                            <Button startIcon={<AddIcon />} size="small" onClick={showInvitationDialog}>
                              Invite new user
                            </Button>
                          )}

                          {!props.hasPermissions && (
                            <props.TooltipPermission>
                              <Button startIcon={<AddIcon />} size="small" disabled>
                                Invite new user
                              </Button>
                            </props.TooltipPermission>
                          )}

                          {/* {props.hasPermissions && !props.hasPlan && (
                            <props.TooltipPlan>
                              <Button startIcon={<AddIcon />} size="small" onClick={props.showPlanSelector}>
                                Invite new user
                              </Button>
                            </props.TooltipPlan>
                          )} */}
                        </>
                      )}
                    </AccessValidator>
                  </Grid>
                </Grid>
              </ListSubheader>
            }
          >
            {users.resources.map((user) => (
              <ListItem key={user.id}>
                <ListItemAvatar>
                  <Avatar src={user.profilePicture as string} />
                </ListItemAvatar>

                <ListItemText primary={user.username as string} secondary={user.email as string} />
                <ListItemSecondaryAction>
                  <AccessValidator permissions="permissions:update">
                    {(props) => (
                      <>
                        {props.hasPermissions && (
                          <IconButton onClick={editUserPermissions.setVisible}>
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                  </AccessValidator>
                </ListItemSecondaryAction>
              </ListItem>
            ))}

            {invitations.resources && invitations.resources.length > 0 && (
              <>
                <ListSubheader>Pending invitations</ListSubheader>

                {invitations.resources.map((invitation) => {
                  if (!invitation.acceptedAt) {
                    return (
                      <ListItem key={invitation.id}>
                        <Chip label={invitation.email as string} /> &nbsp;
                        <Date isoDate={invitation.expiredAt as string} />
                        <Tooltip title="Copy invitation link to clipboard">
                          <IconButton
                            onClick={handleCopyToClipboardInvitationUrl(invitation.inviteUrl as string)}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                    );
                  }
                })}
              </>
            )}
          </List>
        </Paper>
      </div>
    </>
  );
};
