import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useUserUpdate, useUserShow, useFileUpload } from '@koopajs/react';
import { Settings, ErrorMessage } from '@koopajs/mui';
import { Box, Typography, Container, Divider } from '@mui/material';

export const Account: React.FC = () => {
  const { user, errorMessage } = useUserShow();
  const { updateUser } = useUserUpdate();
  const { upload } = useFileUpload();

  const handleProfileChange = useCallback(async (values: { [k: string]: unknown }): Promise<boolean> => {
    return await updateUser(values);
  }, []);

  const handlePhotoChange = useCallback(async (photo: Blob): Promise<void> => {
    const profilePicture = await upload(photo, 'cdn');
    await handleProfileChange({ profilePicture });
  }, []);

  if (!user) {
    return <div>Loading</div>;
  }

  const name = user.firstname || user.lastname ? `${user.firstname || ''} ${user.lastname || ''}` : 'Unknown';

  return (
    <>
      <Helmet>
        <title>My Account - Hoopback</title>
      </Helmet>
      <Container sx={{ mt: 2 }}>
        <Typography variant="h1" sx={{ fontSize: 24 }}>
          My Account
        </Typography>

        <ErrorMessage error={errorMessage} />

        <Box sx={{ py: 2 }}>
          <Settings.PhotoEditor
            label="Profile Picture"
            description="A photo helps personalize your account."
            photoUrl={(user.profilePicture as string) || ''}
            handleValueChange={handlePhotoChange}
          />
        </Box>

        <Divider />

        <Box sx={{ py: 2 }}>
          <Settings.FieldEditor
            label="Name"
            value={name}
            description="The name attribute help personalize your product experience. It is visible to people in your organization only."
            fields={[
              { key: 'firstname', label: 'Firstname' },
              { key: 'lastname', label: 'Lastname' }
            ]}
            defaultValues={user}
            handleValueChange={handleProfileChange}
          />
        </Box>

        <Divider />

        <Divider />

        <Box sx={{ py: 2 }}>
          <Settings.ReadOnly label="Email Address" value={(user.email as string) || 'Unknown'} />
        </Box>

        <Divider />

        <Box sx={{ py: 2 }}>
          <Settings.ReadOnly label="Account ID" value={`u:${user.id}`} />
        </Box>
      </Container>
    </>
  );
};
