import { Dialog, ErrorMessage, Date, TagEditor, Comments } from '@koopajs/mui';
import { useParamsKey, useResourceShow, useResourceUpdate, useResourceDelete } from '@koopajs/react';
import { IObservation } from 'types';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Grid,
  Box,
  Card,
  Avatar,
  IconButton,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Tooltip
} from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ImageType } from 'components/Lists/Observations/ImageType';
import { debounce } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from 'components/Core/theme';

export const ObservationShow: React.FC = () => {
  const id = useParamsKey();
  const request = useResourceShow<IObservation>({ path: '/observations', id });
  const location = useLocation();
  const [currentTypeValue, setCurrentTypeValue] = useState<string | undefined>(undefined);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const type = request?.resource?.type;
    setCurrentTypeValue(type);
  }, [id]);

  const { updateResource, errorMessage } = useResourceUpdate({
    path: '/observations',
    id: id
  });

  const updateDatabase = useCallback(
    debounce(async (latestTypeValue?: string) => {
      if (!latestTypeValue) return;
      await updateResource({ type: latestTypeValue });
    }, 2000),
    [updateResource]
  );

  const isOpen = Boolean(id);
  const history = useHistory();

  const handleClose = useCallback((): void => {
    history.push('/');
  }, []);

  const { deleteResource } = useResourceDelete({ path: '/observations', id });

  const handleArchive = useCallback(async () => {
    await deleteResource();
    handleClose();
    history.push('/');
  }, [deleteResource]);

  const handleShareDocument = useCallback(async (): Promise<void> => {
    const url = window.location.href;
    const shareData = {
      title: `HOOPBACK - Feedback shared with you: ${request?.resource?.type}`,
      text: `Feedback shared with you: ${request?.resource?.type}`,
      url
    };

    if (isLargeScreen) {
      try {
        await navigator.clipboard.writeText(window.location.href);
      } catch (e) {
        //console.log(e);
      }
    } else {
      try {
        await navigator.share(shareData);
      } catch (e) {
        //console.log(e);
      }
    }
  }, [location]);

  const handleImageTypeChange = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      const possibleTypeValues = [undefined, 'bug', 'satisfaction', 'frustration', 'idea'];
      const iconIndex = possibleTypeValues.indexOf(currentTypeValue);
      const newIndex = possibleTypeValues[iconIndex + 1] ? iconIndex + 1 : 1;
      setCurrentTypeValue(possibleTypeValues[newIndex]);
      await updateDatabase(possibleTypeValues[newIndex]);
    },
    [currentTypeValue]
  );

  return (
    <Dialog.View
      title={
        <CardHeader
          avatar={
            <Stack direction="column" alignItems="center">
              <Avatar
                sx={{ backgroundColor: 'white', width: 50, height: 50, cursor: 'pointer' }}
                onClick={handleImageTypeChange}
              >
                <ImageType type={currentTypeValue} />
              </Avatar>
              <Typography variant="caption">{currentTypeValue || 'add type'}</Typography>
            </Stack>
          }
          title={request?.resource?.person?.email || request?.resource?.person?.name || 'Anonymous'}
          subheader={
            <>
              {request?.resource?.sourceEmail && `Email forwarded by ${request?.resource?.sourceEmail}`}
              {request?.resource?.sourceRoute && request?.resource?.sourceRoute}
              {!request?.resource?.sourceRoute && !request?.resource?.sourceEmail && 'Manual entry'}
              &nbsp; &bull; &nbsp; Last updated <Date isoDate={request?.resource?.$updatedAt as string} />
            </>
          }
          action={
            <>
              <Tooltip title="Copy to clipboard">
                <IconButton onClick={handleShareDocument}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Archive">
                <IconButton onClick={handleArchive}>
                  <ArchiveIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
      dialogProps={{ PaperProps: { sx: { height: '100%' } } }}
    >
      <ErrorMessage error={errorMessage} />
      <Grid container spacing={2} sx={{ height: '95%' }}>
        <Grid item xs={12} sm={7} md={8} sx={{ height: '100%' }}>
          <Card sx={{ minHeight: '100%', width: '100%', border: 1, borderColor: '#c3c4c4' }} elevation={0}>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: '16px', mb: '8px' }}>
              <Typography
                align="center"
                color="text.secondary"
                variant="caption"
                sx={{ paddingRight: '16px' }}
              >
                Created on <Date isoDate={request?.resource?.$createdAt || 'N/A'} format="yyyy-MM-dd" />
              </Typography>
            </Stack>
            <CardContent sx={{ height: '100%', width: '100%', padding: 0 }}>
              <Typography variant="body2" sx={{ padding: '16px' }}>
                {request?.resource?.content}
              </Typography>
              {request.resource && request.resource?.attachedFileUri && (
                <Box style={{ textAlign: 'center' }}>
                  <img style={{ maxWidth: '100%' }} src={request.resource.attachedFileUri} />
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} md={4} sx={{ height: '100%' }}>
          <Box>
            <Box sx={{ marginBottom: 5 }}>
              <Typography sx={{ marginBottom: 1 }}>Tags</Typography>
              {request.resource && <TagEditor path="/observations" id={id} resource={request?.resource} />}
            </Box>
            <Box>
              <Typography sx={{ marginBottom: 0 }}>Notes </Typography>
              <Comments
                id={id}
                path="/observations"
                sx={{ height: '500px', overflow: 'auto' }}
                placeholder="Add notes"
                label="Add"
                dense={true}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog.View>
  );
};
